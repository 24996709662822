  <footer class="page-footer">
    <div class="footer-content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 col-sm-6">
            <p class="mb-0 text-muted">&copy; Ipsos 2023 - v.1.0.14</p>
          </div>
          
        </div>
      </div>
    </div>
  </footer>
