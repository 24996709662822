<nav class="navbar fixed-top">
  <div class="d-flex align-items-center navbar-left">
    <a (click)="menuButtonClick($event, sidebar.menuClickCount, sidebar.containerClassnames); false;"
      href="javascript:;" class="menu-button d-none d-md-block">
      <svg class="main" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 17">
        <rect x="0.48" y="0.5" width="7" height="1" />
        <rect x="0.48" y="7.5" width="7" height="1" />
        <rect x="0.48" y="15.5" width="7" height="1" />
      </svg> {{" "}}
      <svg class="sub" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 17">
        <rect x="1.56" y="0.5" width="16" height="1" />
        <rect x="1.56" y="7.5" width="16" height="1" />
        <rect x="1.56" y="15.5" width="16" height="1" />
      </svg>
    </a>

    <a (click)="mobileMenuButtonClick($event, sidebar.containerClassnames)" href="javascript:;"
      class="menu-button-mobile d-xs-block d-sm-block d-md-none">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 17">
        <rect x="0.5" y="0.5" width="25" height="1" />
        <rect x="0.5" y="7.5" width="25" height="1" />
        <rect x="0.5" y="15.5" width="25" height="1" />
      </svg>
    </a>

   
  </div>

  <a class="navbar-logo" href="/">
    <span class="logo-main d-none d-xs-block"></span>
    <span class="logo-main-mobile d-block d-xs-none"></span>
  </a>

  <div class="navbar-right">
    <div class="header-icons d-inline-block align-middle">
      
      <button class="header-icon btn btn-empty d-none d-sm-inline-block" type="button" id="fullScreenButton"
        (click)="fullScreenClick()">
        <i *ngIf="!isFullScreen" class="simple-icon-size-fullscreen"></i>
        <i *ngIf="isFullScreen" class="simple-icon-size-actual"></i>
      </button>

    </div>

    <div class="user d-inline-block" dropdown>
      <button class="btn btn-empty p-0" type="button" dropdownToggle>
        <span class="name mr-1">{{displayName}}</span>
        <span>
          <i class="text-onehalf glyph-icon iconsminds-male-2" ></i>
        </span>
      </button>

      <div class="dropdown-menu dropdown-menu-right mt-3" *dropdownMenu role="menu">
       <!-- <a class="dropdown-item c-pointer" (click)="onResetPwd()">Change password</a>
        <li class="divider dropdown-divider"></li>-->
        <a class="dropdown-item c-pointer" (click)="onSignOut()">Sign out</a>
      </div>
    </div>

  </div>
</nav>
