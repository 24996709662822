const awsmobile = {
    aws_project_region: 'eu-west-3',
    aws_cognito_region: 'eu-west-3',
    aws_user_pools_id: 'eu-west-3_cyvOa7Tw4',

    // PROD
     aws_user_pools_web_client_id: '3crn8173dpj3u3o49cdmvn2krm',

  //   aws_user_pools_web_client_id: '7d04u8kdas54dk6jdliaite3ke',



    oauth: {
        domain: 'ipsosinterviewers.auth.eu-west-3.amazoncognito.com',
        scope: [

            'openid',
            'profile'
        ],
       //   redirectSignIn: 'http://localhost:4200/app/dashboard',
       //   redirectSignOut: 'http://localhost:4200/user/login',
           redirectSignIn: 'https://mapping.ipsosadm.it/app/dashboard',
        redirectSignOut: 'https://mapping.ipsosadm.it/user/login',

        responseType: 'token'
    }
};


export default awsmobile;

