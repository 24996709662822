<div id="root">
  <div class="fixed-background">
    <main>
      <div class="container">
        <div class="row h-100">
          <div class="col-12 col-md-10 mx-auto my-auto">
            <div class="card auth-card">
              <div class="position-relative image-side ">
                
              </div>
              <div class="form-side">
                <a routerLink="/">
                  <span class="logo-main-login"></span>
                </a>
                <h6 class="mb-4">{{ "pages.error-title" | translate }}</h6>
                <p class="mb-0 text-muted text-small mb-0">
                  {{ "pages.error-code" | translate }}
                </p>
                <p class="display-1 font-weight-bold mb-5">404</p>
                <button class="btn btn-primary btn-lg btn-shadow" type="submit">
                  {{ "pages.go-back-home" | translate | uppercase }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</div>
